import React from "react";
import Logo from "../Logo";
import "./ALPHAWHITE3840.css";

function ALPHAWHITE3840(props) {
  const {
    overlapGroup1,
    place,
    alphaDna,
    technologies,
    buildingTheFuture,
    aboutUs,
    atOurSoftwareDeve,
    copyright,
    maskGroup,
    logoProps,
  } = props;

  return (
    <div className="container-center-horizontal">
      <div className="alpha-white-3840 screen">
        <div className="overlap-group1" style={{ backgroundImage: `url(${overlapGroup1})` }}>
          <div className="flex-row">
            <Logo clarityHelixSolid={logoProps.clarityHelixSolid} />
            <div className="frame-2">
              <div className="place">{place}</div>
            </div>
          </div>
          <img className="line-2" src="/img/line-2-1@1x.svg" alt="Line 2" />
          <div className="group-5">
            <div className="group-4">
              <div className="group-3">
                <img className="line-1" src="/img/line-1-1@2x.svg" alt="Line 1" />
                <div className="overlap-group">
                  <h1 className="alpha-dna">{alphaDna}</h1>
                  <div className="technologies">{technologies}</div>
                  <div className="building-the-future">{buildingTheFuture}</div>
                </div>
              </div>
              <div className="rectangle-8"></div>
            </div>
            <div className="group-2">
              <div className="about-us">{aboutUs}</div>
              <p className="at-our-software-deve">{atOurSoftwareDeve}</p>
            </div>
          </div>
          <div className="overlap-group3">
            <img className="line-4" src="/img/line-4-1@1x.svg" alt="Line 4" />
          </div>
          <div className="overlap-group2">
            <p className="copyright alexandria-normal-scarpa-flow-30px">{copyright}</p>
            <img className="mask-group" src={maskGroup} alt="Mask group" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ALPHAWHITE3840;
