import React from "react";
import Logo from "../Logo";
import "./ALPHAWHITE.css";

function ALPHAWHITE(props) {
  const {
    overlapGroup1,
    place,
    alphaDna,
    technologies,
    buildingTheFuture,
    aboutUs,
    atOurSoftwareDeve,
    copyright,
    maskGroup,
    logoProps,
  } = props;

  return (
    <div className="container-center-horizontal">
      <div className="alpha-white screen">
        <div className="overlap-group1-1" style={{ backgroundImage: `url(${overlapGroup1})` }}>
          <div className="flex-row-1">
            <Logo clarityHelixSolid={logoProps.clarityHelixSolid} className={logoProps.className} />
            <div className="frame-2-1">
              <div className="place-2">{place}</div>
            </div>
          </div>
          <img className="line-2-1" src="/img/line-2@1x.svg" alt="Line 2" />
          <div className="group-5-1">
            <div className="group-4-1">
              <div className="group-3-1">
                <img className="line-1-1" src="/img/line-1@2x.svg" alt="Line 1" />
                <div className="overlap-group-1">
                  <div className="alpha-dna-1">{alphaDna}</div>
                  <div className="technologies-1">{technologies}</div>
                  <div className="building-the-future-1">{buildingTheFuture}</div>
                </div>
              </div>
              <div className="rectangle-8-1"></div>
            </div>
            <div className="group-2-1">
              <div className="about-us-1">{aboutUs}</div>
              <p className="at-our-software-deve-1">{atOurSoftwareDeve}</p>
            </div>
          </div>
          <div className="overlap-group3-1">
            <img className="line-4-1" src="/img/line-4@1x.svg" alt="Line 4" />
          </div>
          <div className="overlap-group2-1">
            <p className="copyright-1 alexandria-normal-scarpa-flow-14px">{copyright}</p>
            <img className="mask-group-1" src={maskGroup} alt="Mask group" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ALPHAWHITE;
