import React from "react";
import Logo from "../Logo";
import "./ALPHAWHITE1920.css";

function ALPHAWHITE1920(props) {
  const {
    overlapGroup1,
    place,
    alphaDna,
    technologies,
    buildingTheFuture,
    aboutUs,
    atOurSoftwareDeve,
    copyright,
    maskGroup,
    logoProps,
  } = props;

  return (
    <div className="container-center-horizontal">
      <div className="alpha-white-1920 screen">
        <div className="overlap-group1-2" style={{ backgroundImage: `url(${overlapGroup1})` }}>
          <div className="flex-row-2">
            <Logo clarityHelixSolid={logoProps.clarityHelixSolid} className={logoProps.className} />
            <div className="frame-2-2">
              <div className="place-3">{place}</div>
            </div>
          </div>
          <img className="line-2-2" src="/img/line-2-3@1x.svg" alt="Line 2" />
          <div className="group-5-2">
            <div className="group-4-2">
              <div className="group-3-2">
                <img className="line-1-2" src="/img/line-1-3@2x.svg" alt="Line 1" />
                <div className="overlap-group-2">
                  <div className="alpha-dna-2">{alphaDna}</div>
                  <div className="technologies-2">{technologies}</div>
                  <div className="building-the-future-2 alexandria-normal-scarpa-flow-30px">{buildingTheFuture}</div>
                </div>
              </div>
              <div className="rectangle-8-2"></div>
            </div>
            <div className="group-2-2">
              <div className="about-us-2">{aboutUs}</div>
              <p className="at-our-software-deve-2">{atOurSoftwareDeve}</p>
            </div>
          </div>
          <div className="overlap-group2-2">
            <img className="line-4-2" src="/img/line-4-3@1x.svg" alt="Line 4" />
          </div>
          <div className="overlap-group3-2">
            <p className="copyright-2">{copyright}</p>
            <img className="mask-group-2" src={maskGroup} alt="Mask group" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ALPHAWHITE1920;
