import "./App.css";
import React from "react";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import ALPHAWHITE3840 from "./components/ALPHAWHITE3840";
import ALPHAWHITE from "./components/ALPHAWHITE";
import ALPHAWHITE1920 from "./components/ALPHAWHITE1920";
import ALPHAWHITE1366 from "./components/ALPHAWHITE1366";
import useMediaQuery from "./hooks/mediaQueryHook";

function App() {
  const isDesktop = useMediaQuery("(min-width: 1024px)");
  const isDesktop1 = useMediaQuery("(min-width: 1728px)");
  const isDesktop2 = useMediaQuery("(min-width: 1920px)");
  const isDesktop3 = useMediaQuery("(min-width: 3800px)");



  return (
    <Router>
      <Switch>
        <Route path="/">
          {isDesktop3 ? (
            <ALPHAWHITE3840 {...aLPHAWHITE3840Data} />
          ): isDesktop2 ? (
            <ALPHAWHITE1920 {...aLPHAWHITE1920Data} />
          ): isDesktop1 ? (
            <ALPHAWHITE {...aLPHAWHITEData} />
          ): isDesktop ? (
            <ALPHAWHITE1366 {...aLPHAWHITE1366Data} />
          ): (
            <ALPHAWHITE1366 {...aLPHAWHITE1366Data} />
          )}
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
const logo1Data = {
    clarityHelixSolid: "/img/clarity-helix-solid-1@1x.png",
};

const aLPHAWHITE3840Data = {
    overlapGroup1: "/img/-pngtree-abstract-light-effect-helix-dna-8649341-1-1@1x.png",
    place: "CONTACT",
    alphaDna: "ALPHA DNA",
    technologies: "TECHNOLOGIES",
    buildingTheFuture: "Building the future ...",
    aboutUs: "ABOUT US",
    atOurSoftwareDeve: <React.Fragment>At our software development company, we specialize in building future financial technology that helps businesses and individuals manage their finances more efficiently and securely. <br /><br />Our team of skilled developers and financial experts are committed to staying on the cutting-edge of the latest industry trends and technologies, ensuring that our solutions are always up-to-date and reliable.</React.Fragment>,
    copyright: "© Alpha  DNA Technologies",
    maskGroup: "/img/mask-group-1@1x.png",
    logoProps: logo1Data,
};

const logo2Data = {
    clarityHelixSolid: "/img/clarity-helix-solid@1x.png",
    className: "logo-1",
};

const aLPHAWHITEData = {
    overlapGroup1: "/img/-pngtree-abstract-light-effect-helix-dna-8649341-1@1x.png",
    place: "CONTACT",
    alphaDna: "ALPHA DNA",
    technologies: "TECHNOLOGIES",
    buildingTheFuture: "Building the future ...",
    aboutUs: "ABOUT US",
    atOurSoftwareDeve: <React.Fragment>At our software development company, we specialize in building future financial technology that helps businesses and individuals manage their finances more efficiently and securely. <br /><br />Our team of skilled developers and financial experts are committed to staying on the cutting-edge of the latest industry trends and technologies, ensuring that our solutions are always up-to-date and reliable.</React.Fragment>,
    copyright: "© Alpha  DNA Technologies",
    maskGroup: "/img/mask-group@1x.png",
    logoProps: logo2Data,
};

const logo3Data = {
    clarityHelixSolid: "/img/clarity-helix-solid-3@1x.png",
    className: "logo-2",
};

const aLPHAWHITE1920Data = {
    overlapGroup1: "/img/-pngtree-abstract-light-effect-helix-dna-8649341-1-3@1x.png",
    place: "CONTACT",
    alphaDna: "ALPHA DNA",
    technologies: "TECHNOLOGIES",
    buildingTheFuture: "Building the future ...",
    aboutUs: "ABOUT US",
    atOurSoftwareDeve: <React.Fragment>At our software development company, we specialize in building future financial technology that helps businesses and individuals manage their finances more efficiently and securely. <br /><br />Our team of skilled developers and financial experts are committed to staying on the cutting-edge of the latest industry trends and technologies, ensuring that our solutions are always up-to-date and reliable.</React.Fragment>,
    copyright: "© Alpha  DNA Technologies",
    maskGroup: "/img/mask-group-3@1x.png",
    logoProps: logo3Data,
};

const logo4Data = {
    clarityHelixSolid: "/img/clarity-helix-solid-5@1x.png",
    className: "logo-3",
};

const aLPHAWHITE1366Data = {
    overlapGroup1: "/img/-pngtree-abstract-light-effect-helix-dna-8649341-1-5@1x.png",
    place: "CONTACT",
    alphaDna: "ALPHA DNA",
    technologies: "TECHNOLOGIES",
    buildingTheFuture: "Building the future ...",
    aboutUs: "ABOUT US",
    atOurSoftwareDeve: <React.Fragment>At our software development company, we specialize in building future financial technology that helps businesses and individuals manage their finances more efficiently and securely. <br /><br />Our team of skilled developers and financial experts are committed to staying on the cutting-edge of the latest industry trends and technologies, ensuring that our solutions are always up-to-date and reliable.</React.Fragment>,
    copyright: "© Alpha  DNA Technologies",
    maskGroup: "/img/mask-group-5@1x.png",
    logoProps: logo4Data,
};

