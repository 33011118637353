import React from "react";
import "./Logo.css";

function Logo(props) {
  const { clarityHelixSolid, className } = props;

  return (
    <div className={`logo ${className || ""}`}>
      <img className="clarityhelix-solid" src={clarityHelixSolid} alt="clarity:helix-solid" />
      <div className="place-1">ALPHA</div>
    </div>
  );
}

export default Logo;
