import React from "react";
import Logo from "../Logo";
import "./ALPHAWHITE1366.css";

function ALPHAWHITE1366(props) {
  const {
    overlapGroup1,
    place,
    alphaDna,
    technologies,
    buildingTheFuture,
    aboutUs,
    atOurSoftwareDeve,
    copyright,
    maskGroup,
    logoProps,
  } = props;

  return (
    <div className="container-center-horizontal">
      <div className="alpha-white-1366 screen">
        <div className="overlap-group1-3" style={{ backgroundImage: `url(${overlapGroup1})` }}>
          <div className="flex-row-3">
            <Logo clarityHelixSolid={logoProps.clarityHelixSolid} className={logoProps.className} />
            <div className="frame-2-3">
              <div className="place-4">{place}</div>
            </div>
          </div>
          <img className="line-2-3" src="/img/line-2-5@1x.svg" alt="Line 2" />
          <div className="group-5-3">
            <div className="group-4-3">
              <div className="group-3-3">
                <img className="line-1-3" src="/img/line-1-5@2x.svg" alt="Line 1" />
                <div className="overlap-group-3">
                  <div className="alpha-dna-3">{alphaDna}</div>
                  <div className="technologies-3">{technologies}</div>
                </div>
                <div className="building-the-future-3">{buildingTheFuture}</div>
              </div>
              <div className="rectangle-8-3"></div>
            </div>
            <div className="group-2-3">
              <div className="about-us-3">{aboutUs}</div>
              <p className="at-our-software-deve-3 alexandria-normal-scarpa-flow-14px">{atOurSoftwareDeve}</p>
            </div>
          </div>
          <div className="overlap-group2-3">
            <img className="line-4-3" src="/img/line-4-5@1x.svg" alt="Line 4" />
          </div>
          <div className="overlap-group3-3">
            <p className="copyright-3">{copyright}</p>
            <img className="mask-group-3" src={maskGroup} alt="Mask group" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ALPHAWHITE1366;
